// Use a generator function to create new object instances
const generator = () => ({
  "Television & Film": "tv",
  "Music": "audiotrack",
  "Shopping & Retail": "shopping_bag",
  "Coffee, Tea & Beverages": "emoji_food_beverage",
  "Camera & Photography": "photo_camera",
  "Clothes, Shoes, Handbags & Accessories": "checkroom",
  "Beer, Wine & Spirits": "sports_bar",
  "Sports": "sports_soccer",
  "Electronics & Computers": "memory",
  "Gaming": "sports_esports",
  "Activewear": "ice_skating",
  "Art & Design": "palette",
  "Travel, Tourism & Aviation": "flight",
  "Business & Careers": "business_center",
  "Beauty & Cosmetics": "face",
  "Healthcare & Medicine": "health_and_safety",
  "Jewellery & Watches": "diamond",
  "Restaurants, Food & Grocery": "restaurant",
  "Toys, Children & Baby": "toys",
  "Fitness & Yoga": "self_improvement",
  "Wedding": "favorite",
  "Tobacco & Smoking": "smoking_rooms",
  "Pets": "pets",
  "Healthy Lifestyle": "medical_information",
  "Luxury Goods": "credit_card",
  "Home Decor, Furniture & Garden": "yard",
  "Friends, Family & Relationships": "diversity_2",
  "Cars & Motorbikes": "two_wheeler"
})

// Generate a new instance and return it
export default generator()
